<router>
{
    name: 'Send Email',
}
</router>
<template lang="pug">
v-container
    v-row
        v-col.d-flex
            v-btn(text, @click='goBack', light)
                v-icon(left) arrow_left
                | Communications
    v-row
        v-col
            v-card(light, flat)
                v-stepper(v-model='step', vertical, flat)
                    v-stepper-step(step='1', color='brand', :complete='step > 1') Select Template
                        small(v-if='step > 1') Template: {{ selectedTemplate.name }}
                    v-stepper-content(step='1')
                        v-card
                            v-card-title Select an email template
                            v-card-text
                                v-row
                                    v-col.d-flex.flex-wrap
                                        EmailTemplateCard(v-for='template in templates', :key='template.id', :template='template')
                                            template(v-slot:cardActions)
                                                v-btn.white--text.brand(small, rounded, @click='selectTemplate(template)') Select
                    v-stepper-step(step='2', color='brand', :complete='step > 2') Edit Email
                    v-stepper-content(step='2')
                        v-card
                            v-card-text
                                v-row
                                    v-col
                                        template(v-if='selectedTemplate.allowCustomization')
                                            EmailEditor(:templateId='selectedTemplate.id', sendEmail, @emailEdited='emailEdited')
                                        template(v-else)
                                            v-row
                                                v-col
                                                    span This email template doesn't allow editing. Click "Continue" to proceed.
                            v-card-actions
                                v-spacer
                                v-btn(text, title='Select a different template', @click='step=1') Back
                                v-btn.white--text(@click='step = 3', color='brand') Continue
                    v-stepper-step(step='3', color='brand', :complete='step > 3') Select Recipients
                        small(v-if='step > 3') {{ recipientOption === 'all' ? 'All Customers' : 'Some Customers' }}
                    v-stepper-content(step='3')
                        v-card(flat)
                            v-card-title Recipients
                            v-card-text
                                v-row
                                    v-col
                                        v-radio-group(v-model='recipientOption')
                                            v-radio(label='Send to all customers', color='brand', value='all')
                                            v-radio(label='Send to specific customers', color='brand', value='specific')
                                v-row(v-if='recipientOption === "specific"')
                                    v-col
                                        v-select(color='brand', label='Select Customer(s)', item-text='name', item-value='id', multiple, :items='customers', v-model='selectedCustomers', outlined, dense)
                                        p Customer not showing up? This list only shows customers that are linked to portal users.
                                v-row
                                    v-col
                                        span This list shows users for the selected customers who are subscribed to this type of email communication.
                                v-row
                                    v-col
                                        v-data-table(:items='individualRecipients', :headers='headers')
                                            template(v-slot:item.name='{item}')
                                                span {{ item.firstName }} {{ item.lastName }}
                            v-card-actions
                                v-spacer
                                v-btn(text, title='Back to Edit Email', @click='step=2') Back
                                v-btn.white--text(color='brand', @click='moveToFinalStep') Continue
                    v-stepper-step(step='4', color='brand', :complete='step > 4') Send/Schedule
                    v-stepper-content(step='4')
                        v-row
                            v-col.d-flex
                                v-icon info
                                span Emails will come from the following email address: {{ sendingAddress }}
                        v-row
                            v-col.d-flex
                                v-btn.mr-2.white--text(color='brand', @click='sendEmailNow', :loading='sendingEmail', :disabled='emailSent') Send now
                                v-btn.mr-2.white--text(color='brand', disabled, :loading='sendingEmail', title='This feature will be available in the future!') Schedule for later
                                v-btn.mr-2(text, title='Change recipients', @click='step=3', :loading='sendingEmail', :disabled='emailSent') Back
                        template(v-if='emailSent')
                            v-row
                                v-col
                                    .text-h5.brand--text Email sent!
                            v-row
                                v-col
                                    p Go back to the Communications page by using the button at the top-left of this page.
        v-col.flex-shrink-1.flex-grow-0.justify-center.d-flex
            v-row(align='center', justify='center')
                v-col
                    v-slide-x-reverse-transition
                        emailanimation.mx-10(:state='emailState', transition="slide-x-transition", v-if='!emailSent')

    v-dialog(v-model='showPreview')
        v-card
            v-card-title {{ templateToPreview.subject }}
            v-card-text
                span(v-html='templateToPreview.content')
    v-snackbar(v-model='showAlert', dark, :timeout='3000') {{ snackbarMessage }}

</template>
<script>

import EmailEditor from '@/components/devops/emaileditor';
import EmailTemplateCard from '@/components/devops/emailtemplatecard';
import emailanimation from '@/components/devops/emailanimation';

export default {
    meta: {
        role: 'devopscomms'
    },
    components: {
        EmailEditor,
        EmailTemplateCard,
        emailanimation
    },
    data () {
        return {
            accessKey: 'devopscomms',
            step: 1,
            templates: [],
            showPreview: false,
            templateToPreview: {},
            selectedTemplate: {},
            emailBody: '',
            emailSubject: '',
            customers: [],
            recipientOption: 'all', // options: all | specific,
            selectedCustomers: [],
            sendingEmail: false,
            emailSent: false,
            snackbarMessage: '',
            individualRecipients: [],
            headers: [
                {
                    text: 'User',
                    value: 'name'
                },
                {
                    text: 'Email',
                    value: 'email'
                }
            ],
            sendingAddress: ''
        };
    },
    methods: {
        async getEmailTemplates () {
            const templates = await this.sendCommand({
                action: 'getEmailTemplates'
            });
            this.templates = templates;
        },
        showTemplatePreview (template) {
            this.templateToPreview = template;
            this.showPreview = true;
        },
        selectTemplate (template) {
            this.selectedTemplate = template;
            this.step = 2;
        },
        emailEdited ({subject, content}) {
            this.emailBody = content;
            this.emailSubject = subject;
            if (this.selectedTemplate.allowCustomization) {
                return;
            } else {
                this.step = 3;
            }
        },
        getCustomers () {
            this.socketEmit('getCustomers', ['users'], (results) => {
                results = results.filter(customer => customer.customersUsers.length);
                this.customers = results || [];
            });
        },
        moveToFinalStep () {
            this.step = 4;
        },
        async sendEmailNow () {
            this.sendingEmail = true;
            const parameters = {
                id: this.selectedTemplate.id,
                customers: this.recipientOption === 'all' ? this.customers.map(customer => customer.id) : this.selectedCustomers
            };
            if (this.selectedTemplate.allowCustomization) {
                parameters.subject = this.emailSubject;
                parameters.content = this.emailBody;
            }
            const result = await this.sendCommand({
                action: 'sendManualEmail',
                parameters
            });
            if (result) {
                if (result.err) {
                    this.snackbarMessage = 'There was an error sending the email!';
                }
                else {
                    this.snackbarMessage = 'Sent!';
                }
            }
            this.sendingEmail = false;
            this.emailSent = true;
        },
        async getIndividualRecipients () {
            const result = await this.sendCommand({
                action: 'getIndividualRecipients',
                parameters: {
                    ids: this.customersToEmail,
                    type: this.selectedTemplate.type
                }
            });
            this.individualRecipients = result;
        },
        async getSendingAddress () {
            const result = await this.sendCommand({
                action: 'getSendingAddress'
            });
            this.sendingAddress = result;
        },
        goBack () {
            this.$router.push('/devops/communications');
        }
    },
    computed: {
        showAlert: {
            get () {
                return this.snackbarMessage !== '';
            },
            set (val) {
                if (val === false) {
                    this.snackbarMessage = '';
                }
            },
        },
        customersToEmail () {
            if (this.recipientOption === 'all') {
                return this.customers.map(customer => customer.id);
            } else {
                return this.selectedCustomers;
            }
        },
        emailState () {
            switch (this.step) {
                case 1:
                    return 'blank';
                case 2:
                case 3:
                    return 'composing';
                case 4:
                    return 'ready';
                default:
                    return 'blank';
            }
        }
    },
    watch: {
        customersToEmail: {
            immediate: true,
            handler () {
                this.getIndividualRecipients();
            }
        }
    },
    mounted () {
        this.getEmailTemplates();
        this.getCustomers();
        this.getSendingAddress();
    }
};
</script>
